<template>
	<div class="header" :style="{ background: subjectColor }">
		<div class="logo">
			<img :src="newLogo ? newLogo : logo ? logo : 'https://jkt-one-stand.obs.cn-southwest-2.myhuaweicloud.com/one-stand/icon/morenLogo.png'"
				height="30" width="30" />
			<span>{{ title }}</span>
		</div>
		<div class="collapse-btn" @click="handleCollapseChange">
			<i v-if="!collapse" class="el-icon-s-fold" style="color:#C0CBCC"></i>
			<i v-else class="el-icon-s-unfold" style="color:#C0CBCC"></i>
		</div>
		<div class="header-right">
			<!--      <div style="color: #626161;align-self: center;margin-right: 20px">-->
			<!--        技术服务：4009996885-->
			<!--      </div>-->
			<div class="member_info">
				<!--        <el-badge :value="12" class="item" style="cursor: pointer">-->
<!--				<div style="cursor: pointer">服务</div>-->
				<!--        </el-badge>-->
				<div style="margin: 0 30px 0 30px;cursor: pointer;position:relative" v-if="roleId > 0">
					<div @mouseenter="isPublic2CodeShow = true" @mouseleave="isPublic2CodeShow = false">移动端</div>
					<img :src="public2Code" v-if="isPublic2CodeShow" alt=""
						style="width: 200px;height: 200px;position: absolute;left: -90px;top: 50px;box-shadow: 0px 10px 10px 0px rgba(81, 203, 205, 0.2);">
				</div>
				<el-badge :value="newData" class="item" style="cursor: pointer">
					<div @click="toDataList">资料库</div>
				</el-badge>
			</div>
			<div class="header-user-con">
				<!-- <div class="btn-fullscreen" @click="handleFullScreen">
          <el-tooltip effect="dark" :content="fullScreen?`取消全屏`:`全屏`" placement="bottom">
            <i class="el-icon-rank cblue"></i>
          </el-tooltip>
        </div> -->
				<div class="user-avator" v-if="userInfo.avatarUrl">
					<img :src="userInfo.avatarUrl" />
				</div>
				<el-dropdown class="user-name" trigger="click" @command="handleCommand">
					<span class="el-dropdown-link" style="color:rgba(0, 0, 0, 0.65);">
            <span>{{ userInfo.userName?userInfo.userName:''}}</span>
            <span v-if="userInfo.userName">-</span>
            <span>{{ userInfo.roleId ? 'boss' : '成员' }}</span>
						<i class="el-icon-arrow-down"></i>
					</span>
					<el-dropdown-menu slot="dropdown">

						<el-dropdown-item divided command="logout">退出登录</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</div>
		</div>
	</div>
</template>

<script>
import { querCountIsSeeNum, getFollowCode } from "../../api/management";
import bus from "../../service/bus";
import utils from "../../utils/index";
import {
	queryOemDomain
} from "@/api/oemConfig.js";

export default {
	name: "commonHeader",
	data() {
		return {
			isPublic2CodeShow: false,
			value: 0,
			companyType: localStorage.getItem("companyType"),
			public2Code: '',
			roleId: localStorage.getItem("roleId"),
			if_flag: null,
			collapse: false,
			fullScreen: false,
			userInfo: this.$store.state.loginRoot.userInfo,
			title: '',
			logo: '',
			icon: ''
		};
	},
	mounted() {
		this.getNum()
		this.getM()
	},
	async created() {
		if (document.domain != "localhost" && document.domain != "crm.juketai.com" && document.domain != "192.168.3.24") {
			const oemConfig = await queryOemDomain({ oemDomain: document.domain });
			if (!oemConfig) {
				this.$message.error('oem配置错误,请联系售后');
				return;
			}
			this.setOemConfig(oemConfig);
		} else {
			this.setOemConfig();
		}
	},
	computed: {
		// 主题颜色
		subjectColor() {
			return this.$store.state.subjectColor
		},
		newData() {
			if (this.$store.getters.newData == 0) {
				return ''
			} else {
				return this.$store.getters.newData
			}
		},
		newLogo() {
			return this.$store.getters.newLogo
		}
	},
	methods: {
		//获取行业列表
		getNum() {
			let ajax = {
				data: ''
			}
			querCountIsSeeNum(ajax)
				.then(res => {
					console.log(res)
					this.value = res.data
				})
				.catch(err => {
					console.log(err)
				})
		},
		//获取太阳码
		getM() {
			let ajax = {
				scene: 'SH=SH',
			}
			if (localStorage.getItem("tabrFlag") == 'ZZ') {
				ajax.associationId = localStorage.getItem("associationId")
				ajax.page = 'pages/home/index/main'
			} else if (localStorage.getItem("tabrFlag") == 'QY') {
				ajax.companyId = localStorage.getItem("companyId")
				ajax.page = 'pages/index/main'
			}
			getFollowCode(ajax)
				.then(res => {
					this.public2Code = res.data.url
				})
				.catch(err => {
					console.log(err)
				})
		},

		setOemConfig(oemConfig) {
			//查询所有Oem配置 crm.juketai.com localhost 本地调试
			//获取icon
			var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
			link.type = 'image/x-icon';
			link.rel = 'shortcut icon';
			if (oemConfig) {
				link.href = oemConfig.data.iconLogo;
				document.getElementsByTagName('head')[0].appendChild(link);
				//获取网页标题
				document.title = oemConfig.data.oemName;
				this.title = oemConfig.data.oemName;
				this.logo = oemConfig.data.oemLogo;
				// if (this.roleId == 0) {
				//   this.title = localStorage.getItem("companyName")
				//   this.logo = localStorage.getItem("companyLogo")
				// }else if (this.companyType == 3) {
				//   this.title = localStorage.getItem("associationName")
				//   this.logo = localStorage.getItem("logo")
				// }else{
				//   this.title = localStorage.getItem("companyName")
				//   this.logo = localStorage.getItem("companyLogo")
				// }
			} else {
				// link.href = "聚客台/one-stand/icon/favicon.ico";
				// document.getElementsByTagName('head')[0].appendChild(link);
				//获取网页标题
				if (this.roleId == 0) {
					this.title = localStorage.getItem("companyName")
					this.logo = localStorage.getItem("companyLogo")
				} else if (this.companyType == '444') {
					this.title = localStorage.getItem("associationName")
					this.logo = localStorage.getItem("logo")
				} else {
					if (localStorage.getItem("companyLogo") != undefined && localStorage.getItem("companyLogo") != 'undefined' && localStorage.getItem("companyLogo")) {
						this.logo = localStorage.getItem("companyLogo")
					}
					this.title = localStorage.getItem("companyName")
				}
			}
		},
		// 全屏
		handleFullScreen() {
			let element = document.documentElement;
			if (this.fullScreen) {
				if (document.exitFullscreen) {
					document.exitFullscreen();
				} else if (document.webkitCancelFullScreen) {
					document.webkitCancelFullScreen();
				} else if (document.webkitCancelFullScreen) {
					document.webkitCancelFullScreen();
				} else if (document.msExitFullscreen) {
					document.msExitFullscreen();
				}
			} else {
				if (element.requestFullscreen) {
					element.requestFullscreen();
				} else if (element.webkitRequestFullScreen) {
					element.webkitRequestFullScreen();
				} else if (element.msRequestFullscreen) {
					element.msRequestFullscreen();
				}
			}
			this.fullScreen = !this.fullScreen;
		},

		// 下拉菜单选择
		handleCommand(commond) {
			if (commond === "logout") {
				console.log('00000')
				utils.clearLoginInfo()
			}
		},

		// 控制折叠面板
		handleCollapseChange() {
			this.collapse = !this.collapse;
			bus.$emit("collapse", this.collapse);
		},
		//资料库
		toDataList() {
			this.$router.push("/dataList");
		}
	},
};
</script>

<style scoped>
.header {
	position: relative;
	box-sizing: border-box;
	width: 100%;
	height: 48px;
	font-size: 22px;
	background-color: #6190e8;
	color: #ffffff;
	box-shadow: 0px 2px 6px rgba(115, 127, 128, 0.12);
	z-index: 2;
	/* border-bottom: 1px solid #eee; */
}

.collapse-btn {
	float: left;
	padding: 0 21px;
	cursor: pointer;
	line-height: 48px;
	color: #ffffff;
	margin-left: 23px;
}

.header .logo {
	float: left;
	line-height: 48px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	color: #ffffff;
	margin-left: 25px;
}

.header .logo img {
	vertical-align: middle;
	margin-right: 8px;
	object-fit: cover;
}

.header .logo span {
	font-size: 16px;
	font-family: PingFang SC;
	font-weight: 700;
	color: #51CBCD;
}

.header-right {
	float: right;
	padding-right: 20px;
	display: flex;
}

.header-user-con {
	display: flex;
	height: 48px;
	align-items: center;
}

.btn-fullscreen {
	transform: rotate(45deg);
	margin-right: 5px;
	font-size: 24px;
}

.btn-bell,
.btn-fullscreen {
	position: relative;
	width: 30px;
	height: 30px;
	text-align: center;
	border-radius: 15px;
	cursor: pointer;
}

.btn-bell-badge {
	position: absolute;
	right: 0;
	top: -2px;
	width: 8px;
	height: 8px;
	border-radius: 4px;
	background: #f56c6c;
	color: #999;
}

.btn-bell .el-icon-bell {
	color: #999;
}

.user-name {
	margin-left: 10px;
}

.user-avator {
	margin-left: 10px;
}

.user-avator img {
	display: block;
	width: 40px;
	height: 40px;
	border-radius: 50%;
}

.el-dropdown-link {
	color: #ffffff;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.el-dropdown-menu__item {
	text-align: center;
}

.member_info {
	/*line-height: 48px;*/
	margin-top: 13px;
	display: flex;
	color: #626161;
	font-size: 16px;
	margin-right: 20px;
}</style>
