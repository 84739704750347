<template>
  <div class="sidebar">
<!--    企业-->
    <el-menu
        v-if="if_flag==2"
        :default-active="onRoute"
        class="sidebar-el-menu"
        :collapse="collapse"
        :background-color="subjectColor"
        text-color="#17191A"
        active-text-color="#51CBCD"
        router
        unique-opened
    >
      <template v-for="item in items">
        <template v-if="item.subs">
          <el-submenu :index="item.index"
                      :key="item.index"
                      v-if='item.member'>
            <template slot="title">
              <!-- 预留字体图标 -->
<!--              <svg-->
<!--                  class="icon"-->
<!--                  aria-hidden="true"-->
<!--                  style="margin-right: 12px; font-size: 18px"-->
<!--              >-->
<!--                <use :xlink:href="item.icon"></use>-->
<!--              </svg>-->
              <i :class="item.icon"></i>
              <span slot="title" class="title">{{ item.title }}</span>
            </template>
            <template v-for="subItem in item.subs">
              <template v-if="subItem.root">
                <!-- v-if="subItem.subs && isMain" -->
                <el-submenu
                    v-if="subItem.subs && isMain"
                    :index="subItem.index"
                    :key="subItem.index"
                    class="title"
                >
                  <template slot="title" class="title">{{
                      subItem.title
                    }}
                  </template>
                  <el-menu-item
                      v-for="(threeItem, i) in subItem.subs"
                      :key="i"
                      :index="threeItem.index"
                      class="title"
                  >{{ threeItem.title }}
                  </el-menu-item
                  >
                </el-submenu>
                    <!--v-else-if="isMain || subItem.administrator" -->
                <el-menu-item
                    v-else-if="isMain || subItem.administrator"
                    :index="subItem.index"
                    :key="subItem.id"
                    class="title pl50"
                >{{ subItem.title }}
                </el-menu-item>
              </template>

              <template v-else>
                <el-submenu
                    v-if="subItem.subs && subItem.memberRoot"
                    :index="subItem.index"
                    :key="subItem.index"
                    class="title pl10"
                >
                  <template slot="title" class="title">{{
                      subItem.title
                    }}
                  </template>
                  <el-menu-item
                      v-for="(threeItem, i) in subItem.subs"
                      :key="i"
                      :index="threeItem.index"
                      class="title "
                  >{{ threeItem.title }}
                  </el-menu-item
                  >
                </el-submenu>
                <el-menu-item
                    v-else
                    :index="subItem.index"
                    :key="subItem.id"
                    class="title pl50"
                    :class="!subItem.memberRoot ? 'memberRoot' : '' "
                >{{ subItem.title }}
                </el-menu-item
                >
              </template>
            </template>
          </el-submenu>
        </template>
        <template v-else>
          <el-menu-item :index="item.index" :key="item.index" class="title" v-if='item.member && !item.isAgent'>
            <!-- 预留字体图标 -->
<!--            <svg-->
<!--                class="icon"-->
<!--                aria-hidden="true"-->
<!--                style="margin-right: 7px; font-size: 18px"-->
<!--            >-->
<!--              <use :xlink:href="item.icon"></use>-->
<!--            </svg>-->
            <i :class="item.icon"></i>
            <span slot="title" class="title">
              {{ item.title }}</span>
          </el-menu-item>
          <el-menu-item :index="item.index" :key="item.id" class="title"
                        v-else-if='info.isAgent == 1 && info.roleId'>
            <!-- 代理企业 -->
<!--            <svg-->
<!--                class="icon"-->
<!--                aria-hidden="true"-->
<!--                style="margin-right: 7px; font-size: 18px"-->
<!--            >-->
<!--              <use :xlink:href="item.icon"></use>-->
<!--            </svg>-->
            <i :class="item.icon"></i>
            <span slot="title" class="title">
              {{ item.title }}</span>
          </el-menu-item>
        </template>
      </template>
    </el-menu>
    <!--    服务商-->
    <el-menu
        v-if="if_flag==4"
        :default-active="onRoute"
        class="sidebar-el-menu"
        :collapse="collapse"
        :background-color="subjectColor"
        text-color="#17191A"
        active-text-color="#51CBCD"
        router
        unique-opened
    >
      <template v-for="item in items3">
        <template v-if="item.subs">
          <el-submenu :index="item.index"
                      :key="item.index"
                      v-if='item.member'>
            <template slot="title">
              <!-- 预留字体图标 -->
<!--              <svg-->
<!--                  class="icon"-->
<!--                  aria-hidden="true"-->
<!--                  style="margin-right: 12px; font-size: 18px"-->
<!--              >-->
<!--                <use :xlink:href="item.icon"></use>-->
<!--              </svg>-->
              <i :class="item.icon"></i>
              <span slot="title" class="title">{{ item.title }} </span>
            </template>
            <template v-for="subItem in item.subs">
              <template v-if="subItem.root">
                <el-submenu
                    v-if="subItem.subs && isMain"
                    :index="subItem.index"
                    :key="subItem.index"
                    class="title"
                >
                  <template slot="title" class="title">{{
                      subItem.title
                    }}
                  </template>
                  <el-menu-item
                      v-for="(threeItem, i) in subItem.subs"
                      :key="i"
                      :index="threeItem.index"
                      class="title"
                  >{{ threeItem.title }}
                  </el-menu-item
                  >
                </el-submenu>
                <el-menu-item
                    v-else-if="isMain || subItem.administrator"
                    :index="subItem.index"
                    :key="subItem.id"
                    class="title pl50"
                >{{ subItem.title }}
                </el-menu-item>
              </template>

              <template v-else>
                <el-submenu
                    v-if="subItem.subs && subItem.memberRoot"
                    :index="subItem.index"
                    :key="subItem.index"
                    class="title pl10"
                >
                  <template slot="title" class="title">{{
                      subItem.title
                    }}
                  </template>
                  <el-menu-item
                      v-for="(threeItem, i) in subItem.subs"
                      :key="i"
                      :index="threeItem.index"
                      class="title "
                  >{{ threeItem.title }}
                  </el-menu-item
                  >
                </el-submenu>
                <el-menu-item
                    v-else
                    :index="subItem.index"
                    :key="subItem.id"
                    class="title pl50"
                    :class="!subItem.memberRoot ? 'memberRoot' : '' "
                >{{ subItem.title }}
                </el-menu-item
                >
              </template>
            </template>
          </el-submenu>
        </template>
        <template v-else>
          <el-menu-item :index="item.index" :key="item.index" class="title" v-if='item.member && !item.isAgent'>
            <!-- 预留字体图标 -->
<!--            <svg-->
<!--                class="icon"-->
<!--                aria-hidden="true"-->
<!--                style="margin-right: 7px; font-size: 18px"-->
<!--            >-->
<!--              <use :xlink:href="item.icon"></use>-->
<!--            </svg>-->
            <i :class="item.icon"></i>
            <span slot="title" class="title">
              {{ item.title }}</span>
          </el-menu-item>
          <el-menu-item :index="item.index" :key="item.id" class="title"
                        v-else-if='info.isAgent == 1 && info.roleId'>
            <!-- 代理企业 -->
<!--            <svg-->
<!--                class="icon"-->
<!--                aria-hidden="true"-->
<!--                style="margin-right: 7px; font-size: 18px"-->
<!--            >-->
<!--              <use :xlink:href="item.icon"></use>-->
<!--            </svg>-->
            <i :class="item.icon"></i>
            <span slot="title" class="title">
              {{ item.title }}</span>
          </el-menu-item>
        </template>
      </template>
    </el-menu>
<!--    组织-->
    <el-menu
        v-if="if_flag==1"
        :default-active="onRoute"
        class="sidebar-el-menu"
        :collapse="collapse"
        :background-color="subjectColor"
        text-color="#17191A"
        active-text-color="#51CBCD"
        router
        unique-opened
    >
      <template v-for="item in organizationList">
        <template v-if="item.childs">
          <el-submenu :index="item.index"
                      :key="item.index">
            <template slot="title">
              <!-- 预留字体图标 -->
<!--              <svg-->
<!--                  class="icon"-->
<!--                  aria-hidden="true"-->
<!--                  style="margin-right: 12px; font-size: 18px"-->
<!--              >-->
<!--                <use :xlink:href="item.icon"></use>-->
<!--              </svg>-->
              <i :class="item.icon"></i>
              <span slot="title" class="title">{{ item.title }} </span>
            </template>
            <template v-for="subItem in item.childs">
              <template>
                <el-menu-item
                    :index="subItem.index"
                    :key="subItem.index"
                    class="title pl50"
                >{{ subItem.title }}
                </el-menu-item>
              </template>
            </template>
          </el-submenu>
        </template>
        <template v-else>
          <el-menu-item :index="item.index" :key="item.index" class="title">
            <!-- 预留字体图标 -->
<!--            <svg-->
<!--                class="icon"-->
<!--                aria-hidden="true"-->
<!--                style="margin-right: 7px; font-size: 18px"-->
<!--            >-->
<!--              <use :xlink:href="item.icon"></use>-->
<!--            </svg>-->
            <i :class="item.icon"></i>
            <span slot="title" class="title">
              {{ item.title }}</span>
          </el-menu-item>
        </template>
      </template>
    </el-menu>
<!--    员工-->
    <el-menu
        v-if="if_flag==3"
        :default-active="onRoute"
        class="sidebar-el-menu"
        :collapse="collapse"
        :background-color="subjectColor"
        text-color="#17191A"
        active-text-color="#51CBCD"
        router
        unique-opened
    >
      <template v-for="item in items2">
        <template v-if="item.subs">
          <el-submenu :index="item.index"
                      :key="item.index"
                      v-if='item.member'>
            <template slot="title">
              <!-- 预留字体图标 -->
<!--              <svg-->
<!--                  class="icon"-->
<!--                  aria-hidden="true"-->
<!--                  style="margin-right: 12px; font-size: 18px"-->
<!--              >-->
<!--                <use :xlink:href="item.icon"></use>-->
<!--              </svg>-->
              <i :class="item.icon"></i>
              <span slot="title" class="title">{{ item.title }} </span>
            </template>
            <template v-for="subItem in item.subs">
              <template v-if="subItem.root">
                <el-submenu
                    v-if="subItem.subs && isMain"
                    :index="subItem.index"
                    :key="subItem.index"
                    class="title"
                >
                  <template slot="title" class="title">{{
                      subItem.title
                    }}
                  </template>
                  <el-menu-item
                      v-for="(threeItem, i) in subItem.subs"
                      :key="i"
                      :index="threeItem.index"
                      class="title"
                  >{{ threeItem.title }}
                  </el-menu-item
                  >
                </el-submenu>
                <el-menu-item
                    v-else-if="isMain || subItem.administrator"
                    :index="subItem.index"
                    :key="subItem.id"
                    class="title pl50"
                >{{ subItem.title }}
                </el-menu-item>
              </template>

              <template v-else>
                <el-submenu
                    v-if="subItem.subs && subItem.memberRoot"
                    :index="subItem.index"
                    :key="subItem.index"
                    class="title pl10"
                >
                  <template slot="title" class="title">{{
                      subItem.title
                    }}
                  </template>
                  <el-menu-item
                      v-for="(threeItem, i) in subItem.subs"
                      :key="i"
                      :index="threeItem.index"
                      class="title "
                  >{{ threeItem.title }}
                  </el-menu-item
                  >
                </el-submenu>
                <el-menu-item
                    v-else
                    :index="subItem.index"
                    :key="subItem.id"
                    class="title pl50"
                    :class="!subItem.memberRoot ? 'memberRoot' : '' "
                >{{ subItem.title }}
                </el-menu-item
                >
              </template>
            </template>
          </el-submenu>
        </template>
        <template v-else>
          <el-menu-item :index="item.index" :key="item.index" class="title" v-if='item.member && !item.isAgent'>
            <!-- 预留字体图标 -->
<!--            <svg-->
<!--                class="icon"-->
<!--                aria-hidden="true"-->
<!--                style="margin-right: 7px; font-size: 18px"-->
<!--            >-->
<!--              <use :xlink:href="item.icon"></use>-->
<!--            </svg>-->
            <i :class="item.icon"></i>
            <span slot="title" class="title">
              {{ item.title }}</span>
          </el-menu-item>
          <el-menu-item :index="item.index" :key="item.id" class="title"
                        v-else-if='info.isAgent == 1 && info.roleId'>
            <!-- 代理企业 -->
<!--            <svg-->
<!--                class="icon"-->
<!--                aria-hidden="true"-->
<!--                style="margin-right: 7px; font-size: 18px"-->
<!--            >-->
<!--              <use :xlink:href="item.icon"></use>-->
<!--            </svg>-->
            <i :class="item.icon"></i>
            <span slot="title" class="title">
              {{ item.title }}</span>
          </el-menu-item>
        </template>
      </template>
    </el-menu>
  </div>
</template>

<script>
import {queryListLevelMenum} from "@/api/settlement";
import bus from "../../service/bus";
import {mapState} from 'vuex';
import console from "console";

export default {
  name: "commonSidebar",
  data() {
    return {
      isFlag:false,
      collapse: false,
      isMain: false,
      member: false,
      info: '',
      //企业
      items: [],
      //服务商
      items3: [
        {
          icon: "iconfont icon-gongzuotai",
          index: "dashboard",
          title: "工作台",
          member: true
        },
        {
          icon: "iconfont icon-kehuguanli",
          index: "customer",
          title: "客户管理",
          member: true,
          type: 'customer',
          subs: [
            {
              index: "customerInput",
              title: "客户录入",
              memberRoot: true,
            },
            {
              index: "customerManagement",
              title: "客户管理",
              memberRoot: true,
            },
            {
              index: "totalCustomer",
              title: "总客户池",
              memberRoot: true,
              id: '01',
            },
            // {
            //   index: "visitCustomers",
            //   title: "访问客户",
            // },
          ],
        },
        {
          icon: "iconfont icon-qiyeguanli-01",
          index: "3",
          title: "企业管理",
          member: true,
          id: '02',
          subs: [
            {
              index: "corporateInformation",
              title: "企业信息",
              memberRoot: true,
              id: '020'
            },
            {
              index: "enterpriseMember",
              title: "企业成员",
              id: '021',
              memberRoot: true,
            },
            {
              index: "advancedForm",
              title: "高级表单",
              memberRoot: true,
              id: '022'
            },
            // {
            //   index: "websiteSettings",
            //   title: "官网设置",
            // },
            {
              index: "advertisingManagement",
              title: "广告图管理",
              memberRoot: true,
              id: '023'
            },
          ],
        },
        {
          icon: "iconfont icon-dingdanguanli",
          index: "orderManagement",
          title: "订单管理",
          member: true,
          id: '03',
        },
        {
          icon: "iconfont icon-shangpinguanli",
          index: "merchandiseManagement",
          title: "商品管理",
          member: true,
          id: '04',
          subs: [
            {
              index: "management",
              title: "商品管理",
              memberRoot: true,
              id: '040'
            },
            {
              index: "appointmentManagement",
              title: "预约管理",
              memberRoot: true,
              id: '041'
            },
            // {
            //   index: "freightManagement",
            //   title: "运费管理",
            // },
          ],
        },
        {
          icon: "iconfont icon-yingxiaoguanli",
          index: "marketingActivities",
          title: "营销活动",
          member: true,
          id: '05',
          subs: [
            {
              index: "distribution",
              title: "分销",
              id: '050',
              memberRoot: true,
              subs: [
                {
                  index: "distributionStatistics",
                  title: "分销统计",
                  memberRoot: true,
                },
                {
                  index: "personnelApplication",
                  title: "人员申请",
                  memberRoot: true,
                },
                {
                  index: "distributionLevel",
                  title: "分销等级",
                  memberRoot: true,
                },
                {
                  index: "distributionOrder",
                  title: "分销订单",
                  memberRoot: true,
                },
                {
                  index: "accountTransfer",
                  title: "到账流水",
                  memberRoot: true,
                },
              ],
            },
            {
              index: "joinGroup",
              title: "拼团",
              memberRoot: true,
              id: '051',
              subs: [
                {
                  index: "productList",
                  title: "拼团商品列表",
                  memberRoot: true,
                },
                {
                  index: "listParticipants",
                  title: "参团列表",
                  memberRoot: true,
                },
              ],
            },
            {
              index: "spike",
              title: "秒杀",
              memberRoot: true,
              id: '052',
              subs: [
                {
                  index: "spikeList",
                  title: "秒杀商品列表",
                  memberRoot: true,
                },
              ],
            },
            {
              index: "couponManagement",
              title: "优惠券管理",
              id: '053',
              memberRoot: true,
              subs: [
                {
                  index: "couponList",
                  title: "优惠券列表",
                  memberRoot: true,
                },
              ],
            },
          ],
        },
        {
          icon: "iconfont icon-caiwuguanli",
          index: "financialManagement",
          title: "财务管理",
          member: true,
          id: '06',
          subs: [
            {
              index: "commissionManagement",
              title: "佣金管理",
              memberRoot: true,
            },
            // {
            //   index: "moneyManagement",
            //   title: "资金管理",
            // },
          ],
        },
        {
          icon: "iconfont icon-huokewenzhangguanli",
          index: "articleManagement",
          title: "获客文章管理",
          member: true,
          id: '07',
          subs: [
            {
              index: "customerArticles",
              title: "获客文章",
              memberRoot: true,
              id: '070'
            },
            {
              index: "employeeData",
              title: "员工数据",
              memberRoot: true,
              id: '071'
            },
            {
              index: "visitorData",
              title: "访客数据",
              memberRoot: true,
              id: '072'
            },
          ],
        },
        // {
        //   icon: "iconfont icon-duanshipinguanli",
        //   index: "videoManage",
        //   title: "短视频管理",
        //   member: true,
        //   id: '08',
        // },
        // {
        //   icon: "iconfont icon-zengzhifuwu",
        //   index: "addedServices",
        //   title: "增值服务",
        //   member: true,
        //   id: '09',
        //   subs: [
        //     {
        //       index: "myNeeds",
        //       title: "我的需求",
        //       memberRoot: true,
        //     },
        //     {
        //       index: "demandCheck",
        //       title: "需求审核",
        //       root: true,
        //       administrator: false,
        //       memberRoot: true,
        //       id: '090'
        //     },
            // {
            //   index: "demandContactsCheck",
            //   title: "人脉集市审核",
            //   root: true,
            //   administrator: false,
            //   memberRoot: true,
            //   id: '092'
            // },
            // {
            //   index: "demandProtuctsCheck",
            //   title: "产品中心审核",
            //   root: true,
            //   administrator: false,
            //   memberRoot: true,
            //   id: '091'
            // },
            // {
            //   index: "demandPublishSetting",
            //   title: "发布设置",
            //   memberRoot: true,
            //   administrator: false,
            //   root: true,
            //   id: '093'
            // },
            // {
            //   index: "demandReport",
            //   title: "举报处理",
            //   memberRoot: true,
            //   administrator: false,
            //   root: true,
            //   id: '090'
            // },
            // {
            //   index: "demandCustomize",
            //   title: "自定义设置",
            //   memberRoot: true,
            //   administrator: false,
            //   root: true,
            //   id: '094'
            // },

        //   ],
        // },


        // {
        //   icon: '#icon-share',
        //   index: "sharingPromotion",
        //   title: "分享推广管理",
        //   member: true,
        //   id: '010',
        //   subs: [
        //     {
        //       index: "posterAtlas",
        //       title: "海报图集",
        //       memberRoot: true,
        //     },
        //     {
        //       index: "clockData",
        //       title: "打卡数据",
        //       memberRoot: true,
        //     },
        //   ],
        // },
        {
          icon: "iconfont icon-xiaochengxushezhi",
          index: "miniProgram",
          title: "小程序设置",
          member: true,
          subs: [
            {
              index: "appletConfig",
              title: "基础设置",
              memberRoot: true,
            },
            // {
            //   index: "basicSetting",
            //   title: "小程序配置",
            //   root:true,
            //   memberRoot:true,
            // },
            {
              index: "mallSetting",
              title: "商城设置",
              memberRoot: true,
            },
          ],
        },
        {
          icon: "iconfont icon-fuwushangzhifu",
          index: "serviceProviders",
          title: "服务商支付",
          member: true,
          subs: [
            {
              index: "toPayData",
              title: "1618870452",
              memberRoot: true,
            },
          ],
        },
      ],
      //组织
      items1: [
        {
          icon: "#icon-dashboard",
          index: "dashboard",
          title: "工作台",
          member: true
        },
        {
          icon: "#icon-team",
          index: "shInformation",
          title: "组织信息",
          member: true
        },
        {
          icon: "#icon-bank",
          index: "members",
          title: "组织设置",
          member: true,
          type: 'customer',
          subs: [
            {
              index: "membersLevel",
              title: "会员级别",
              memberRoot: true,
            },
            {
              index: "membersMoney",
              title: "会费设置",
              memberRoot: true,
            },
            {
              index: "membershipManagement",
              title: "入会管理",
              memberRoot: true,
            },
            {
              index: "memberRules",
              title: "入会协议规则",
              memberRoot: true,
            },
            {
              index: "memberMoneyRules",
              title: "会费协议规则",
              memberRoot: true,
            }
          ],
        },
        {
          icon: "#icon-shopping",
          index: "3",
          title: "商城管理",
          member: true,
          id: '02',
          subs: [
            {
              index: "managementSh",
              title: "商品管理",
              memberRoot: true,
              id: '020'
            },
            {
              index: "orderManagementSh",
              title: "订单管理",
              id: '021',
              memberRoot: true,
            },
            {
              index: "appointmentManagementSH",
              title: "预约管理",
              memberRoot: true,
              id: '022'
            },
            {
              index: "mallSettingSH",
              title: "商城设置",
              memberRoot: true,
            },
            {
              index: "demandProtuctsCheckSH",
              title: "商品审核",
              memberRoot: true,
              id: '022'
            },
            {
              index: "statements",
              title: "结算单",
              memberRoot: true,
              id: '023'
            },
          ],
        },
        {
          icon: "#icon-file-text",
          index: "website",
          title: "组织官网",
          member: true,
          id: '03',
          subs: [
            {
              index: "basicSetup",
              title: "基本设置",
              memberRoot: true,
              id: '030'
            },
            {
              index: "navSetup",
              title: "导航设置",
              memberRoot: true,
              id: '031'
            },
            {
              index: "templateLibrary",
              title: "模板设置",
              memberRoot: true,
              id: '032'
            },
          ],
        },
        {
          icon: "#icon-solution",
          index: "membersGL",
          title: "会员管理",
          member: true,
          id: '04',
          subs: [
            {
              index: "membersList",
              title: "会员列表",
              memberRoot: true,
              id: '040'
            },
            {
              index: "membership",
              title: "入会审核",
              memberRoot: true,
              id: '043'
            },
            {
              index: "membersFc",
              title: "会长风采",
              memberRoot: true,
              id: '046'
            },
            {
              index: "goodMembersList",
              title: "优秀会员推荐",
              memberRoot: true,
              id: '041'
            },
            {
              index: "membersGLMoney",
              title: "会费管理",
              memberRoot: true,
              id: '042'
            },

            {
              index: "paymentRecords",
              title: "缴费记录",
              memberRoot: true,
              id: '044'
            },
            {
              index: "fansManagement",
              title: "粉丝管理",
              memberRoot: true,
              id: '045'
            },
            {
              index: "membersDel",
              title: "会员回收站",
              memberRoot: true,
              id: '045'
            },
          ],
        },
        {
          icon: "#icon-gift",
          index: "management",
          title: "内容管理",
          member: true,
          id: '05',
          subs: [
            {
              index: "homePage",
              title: "首页轮播",
              id: '050',
              memberRoot: true,
            },
            {
              index: "announcementList",
              title: "公告列表",
              memberRoot: true,
              id: '051',
            },
            {
              index: "homeMenu",
              title: "首页菜单",
              memberRoot: true,
              id: '052',
            },
            {
              index: "membersWork",
              title: "组织工作",
              id: '053',
              memberRoot: true,
            },
            {
              index: "articleManagement",
              title: "文章管理",
              id: '054',
              memberRoot: true,
            },
            {
              index: "donationManagement",
              title: "捐赠管理",
              id: '055',
              memberRoot: true,
            },
          ],
        },
        {
          icon: "#icon-creditcard",
          index: "square",
          title: "广场管理",
          member: true,
          id: '06',
          subs: [
            {
              index: "shufflingList",
              title: "轮播图",
              id: '060',
              memberRoot: true,
            },
            {
              index: "dynamicList",
              title: "动态列表",
              memberRoot: true,
              id: '061',
            }
          ],
        },
        {
          icon: "#icon-key",
          index: "userFeedback",
          title: "用户反馈",
          member: true,
          id: '06',
        },
        {
          icon: "#icon-cluster",
          index: "agentManage",
          title: "代理企业",
          member: true,
        },
        {
          icon: "#icon-video",
          index: "videoManageZZ",
          title: "短视频管理",
          member: true,
          id: '08',
        },
        {
          icon: "#icon-api",
          index: "miniProgram",
          title: "小程序设置",
          member: true,
          subs: [
            {
              index: "appletConfig",
              title: "基础设置",
              memberRoot: true,
            },
          ],
        },
        {
          icon: "#icon-api",
          index: "systemSet",
          title: "系统设置",
          member: true,
          id: '07',
          subs: [
            {
              index: "systemSet",
              title: "基础设置",
              memberRoot: true,
            },
            {
              index: "roleManagement",
              title: "角色管理",
              memberRoot:true,
            },
            {
              index: "accountManagement",
              title: "账号管理",
              memberRoot:true,
            },
          ],
        },
      ],
      //员工
      // items2: [
      //   {
      //     icon: "iconfont icon-gongzuotai",
      //     index: "dashboard",
      //     title: "工作台",
      //     member: true
      //   },
      //   {
      //     icon: "iconfont icon-kehuguanli",
      //     index: "customer",
      //     title: "客户管理",
      //     member: true,
      //     type: 'customer',
      //     subs: [
      //       {
      //         index: "customerInput",
      //         title: "客户录入",
      //         memberRoot: true,
      //       },
      //       {
      //         index: "customerManagement",
      //         title: "客户管理",
      //         memberRoot: true,
      //       },
      //       // {
      //       //   index: "totalCustomer",
      //       //   title: "总客户池",
      //       //   memberRoot: true,
      //       //   id: '01',
      //       // },
      //       // {
      //       //   index: "visitCustomers",
      //       //   title: "访问客户",
      //       // },
      //     ],
      //   },
      //   {
      //     icon: "iconfont icon-qiyeguanli-01",
      //     index: "3",
      //     title: "企业管理",
      //     member: true,
      //     id: '02',
      //     subs: [
      //       // {
      //       //   index: "corporateInformation",
      //       //   title: "企业信息",
      //       //   memberRoot: true,
      //       //   id: '020'
      //       // },
      //       {
      //         index: "enterpriseMember",
      //         title: "企业成员",
      //         id: '021',
      //         memberRoot: true,
      //       },
      //       {
      //         index: "advancedForm",
      //         title: "高级表单",
      //         memberRoot: true,
      //         id: '022'
      //       },
      //       // {
      //       //   index: "websiteSettings",
      //       //   title: "官网设置",
      //       // },
      //       // {
      //       //   index: "advertisingManagement",
      //       //   title: "广告图管理",
      //       //   memberRoot: true,
      //       //   id: '023'
      //       // },
      //     ],
      //   },
      //   // {
      //   //   icon: "iconfont icon-duanshipinguanli",
      //   //   index: "videoManage",
      //   //   title: "短视频管理",
      //   //   member: true,
      //   //   id: '08',
      //   // },
      //   // {
      //   //   icon: "iconfont icon-zengzhifuwu",
      //   //   index: "addedServices",
      //   //   title: "增值服务",
      //   //   member: true,
      //   //   id: '09',
      //   //   subs: [
      //   //     {
      //   //       index: "myNeeds",
      //   //       title: "我的需求",
      //   //       memberRoot: true,
      //   //     },
      //       // {
      //       //   index: "demandCheck",
      //       //   title: "需求审核",
      //       //   root: true,
      //       //   administrator: false,
      //       //   memberRoot: true,
      //       //   id: '090'
      //       // },
      //       // {
      //       //   index: "demandContactsCheck",
      //       //   title: "人脉集市审核",
      //       //   root: true,
      //       //   administrator: false,
      //       //   memberRoot: true,
      //       //   id: '092'
      //       // },
      //       // {
      //       //   index: "demandProtuctsCheck",
      //       //   title: "产品中心审核",
      //       //   root: true,
      //       //   administrator: false,
      //       //   memberRoot: true,
      //       //   id: '091'
      //       // },
      //       // {
      //       //   index: "demandPublishSetting",
      //       //   title: "发布设置",
      //       //   memberRoot: true,
      //       //   administrator: false,
      //       //   root: true,
      //       //   id: '093'
      //       // },
      //       // {
      //       //   index: "demandReport",
      //       //   title: "举报处理",
      //       //   memberRoot: true,
      //       //   administrator: false,
      //       //   root: true,
      //       //   id: '090'
      //       // },
      //       // {
      //       //   index: "demandCustomize",
      //       //   title: "自定义设置",
      //       //   memberRoot: true,
      //       //   administrator: false,
      //       //   root: true,
      //       //   id: '094'
      //       // },

      //     // ],
      //   // },


      //   // {
      //   //   icon: '#icon-share',
      //   //   index: "sharingPromotion",
      //   //   title: "分享推广管理",
      //   //   member: true,
      //   //   id: '010',
      //   //   subs: [
      //   //     {
      //   //       index: "posterAtlas",
      //   //       title: "海报图集",
      //   //       memberRoot: true,
      //   //     },
      //   //     {
      //   //       index: "clockData",
      //   //       title: "打卡数据",
      //   //       memberRoot: true,
      //   //     },
      //   //   ],
      //   // },
      //   {
      //     icon: "iconfont icon-xiaochengxushezhi",
      //     index: "miniProgram",
      //     title: "小程序设置",
      //     member: true,
      //     subs: [
      //       {
      //         index: "appletConfig",
      //         title: "基础设置",
      //         memberRoot: true,
      //       },
      //       // {
      //       //   index: "basicSetting",
      //       //   title: "小程序配置",
      //       //   root:true,
      //       //   memberRoot:true,
      //       // },
      //       {
      //         index: "mallSetting",
      //         title: "商城设置",
      //         memberRoot: true,
      //       },
      //     ],
      //   },
      // ],
      items2: [
        {
          icon: "iconfont icon-gongzuotai",
          index: "dashboard",
          title: "工作台",
          member: true
        },
        {
          icon: "iconfont icon-kehuguanli",
          index: "customer",
          title: "客户管理",
          member: true,
          type: 'customer',
          subs: [
            {
              index: "customerInput",
              title: "客户录入",
              memberRoot: true,
            },
            {
              index: "customerManagement",
              title: "客户管理",
              memberRoot: true,
            },
            {
              index: "totalCustomer",
              title: "总客户池",
              memberRoot: true,
              id: '01',
            },
            // {
            //   index: "visitCustomers",
            //   title: "访问客户",
            // },
          ],
        },
        {
          icon: "iconfont icon-qiyeguanli-01",
          index: "3",
          title: "企业管理",
          member: true,
          id: '02',
          subs: [
            {
              index: "corporateInformation",
              title: "企业信息",
              memberRoot: true,
              id: '020'
            },
            {
              index: "enterpriseMember",
              title: "企业成员",
              id: '021',
              memberRoot: true,
            },
            {
              index: "advancedForm",
              title: "高级表单",
              memberRoot: true,
              id: '022'
            },
            // {
            //   index: "websiteSettings",
            //   title: "官网设置",
            // },
            {
              index: "advertisingManagement",
              title: "广告图管理",
              memberRoot: true,
              id: '023'
            },
            {
              index: "businessCard",
              title: "编辑名片",
              memberRoot: true,
              id: '024'
            },
            {
              index: "commonFun",
              title: "常用功能",
              memberRoot: true,
              id: '025'
            },
          ],
        },
        {
          icon: "iconfont icon-yidongguanwangguanli",
          index: "mobileWeb",
          title: "移动官网管理",
          member: true,
          id: '11',
        },
        {
          icon: "iconfont icon-dingdanguanli",
          index: "orderManagement",
          title: "订单管理",
          member: true,
          id: '03',
        },
        {
          icon: "iconfont icon-jiesuandan",
          index: "statementsQY",
          title: "结算单",
          member: true,
          id: '10'
        },
        {
          icon: "iconfont icon-shangpinguanli",
          index: "merchandiseManagement",
          title: "商品管理",
          member: true,
          id: '04',
          subs: [
            {
              index: "management",
              title: "商品管理",
              memberRoot: true,
              id: '040'
            },
            {
              index: "appointmentManagement",
              title: "预约管理",
              memberRoot: true,
              id: '041'
            },
            // {
            //   index: "demandProtuctsCheck",
            //   title: "商品审核",
            //   // root: true,
            //   // administrator: false,
            //   memberRoot: true,
            //   id: '091'
            // },
            // {
            //   index: "freightManagement",
            //   title: "运费管理",
            // },
          ],
        },
        {
          icon: "iconfont icon-yingxiaoguanli",
          index: "marketingActivities",
          title: "营销活动",
          member: true,
          id: '05',
          subs: [
            {
              index: "distribution",
              title: "分销",
              id: '050',
              memberRoot: true,
              subs: [
                {
                  index: "distributionStatistics",
                  title: "分销统计",
                  memberRoot: true,
                },
                {
                  index: "personnelApplication",
                  title: "人员申请",
                  memberRoot: true,
                },
                {
                  index: "distributionLevel",
                  title: "分销等级",
                  memberRoot: true,
                },
                {
                  index: "distributionOrder",
                  title: "分销订单",
                  memberRoot: true,
                },
                {
                  index: "accountTransfer",
                  title: "到账流水",
                  memberRoot: true,
                },
              ],
            },
            {
              index: "joinGroup",
              title: "拼团",
              memberRoot: true,
              id: '051',
              subs: [
                {
                  index: "productList",
                  title: "拼团商品列表",
                  memberRoot: true,
                },
                {
                  index: "listParticipants",
                  title: "参团列表",
                  memberRoot: true,
                },
              ],
            },
            {
              index: "spike",
              title: "秒杀",
              memberRoot: true,
              id: '052',
              subs: [
                {
                  index: "spikeList",
                  title: "秒杀商品列表",
                  memberRoot: true,
                },
              ],
            },
            {
              index: "couponManagement",
              title: "优惠券管理",
              id: '053',
              memberRoot: true,
              subs: [
                {
                  index: "couponList",
                  title: "优惠券列表",
                  memberRoot: true,
                },
              ],
            },
          ],
        },
        {
          icon: "iconfont icon-caiwuguanli",
          index: "financialManagement",
          title: "财务管理",
          member: true,
          id: '06',
          subs: [
            {
              index: "commissionManagement",
              title: "佣金管理",
              memberRoot: true,
            },
            // {
            //   index: "moneyManagement",
            //   title: "资金管理",
            // },
          ],
        },
        {
          icon: "iconfont icon-huokewenzhangguanli",
          index: "articleManagement",
          title: "动态管理",
          member: true,
          id: '07',
          subs: [
            {
              index: "toPromote",
              title: "推广文章管理",
              id: '070',
              memberRoot: true,
              subs: [
                {
                  index: "customerArticles",
                  title: "推广文章",
                  memberRoot: true,
                },
                {
                  index: "employeeData",
                  title: "员工数据",
                  memberRoot: true,
                },
                {
                  index: "visitorData",
                  title: "访客数据",
                  memberRoot: true,
                }
              ],
            },
            {
              index: "enterpriseDT",
              title: "企业动态",
              id: '071',
              memberRoot: true,
            },
          ],
        },
        {
          icon: "iconfont icon-duanshipinguanli",
          index: "videoManage",
          title: "短视频管理",
          member: true,
          id: '08',
        },
        // {
        //   icon: "iconfont icon-zengzhifuwu",
        //   index: "addedServices",
        //   title: "增值服务",
        //   member: true,
        //   id: '09',
        //   subs: [
        //     {
        //       index: "myNeeds",
        //       title: "我的需求",
        //       memberRoot: true,
        //     },
        //     {
        //       index: "demandCheck",
        //       title: "需求审核",
        //       root: true,
        //       administrator: false,
        //       memberRoot: true,
        //       id: '090'
        //     },
        //   ],
        // },
        // {
        //   icon: '#icon-share',
        //   index: "sharingPromotion",
        //   title: "分享推广管理",
        //   member: true,
        //   id: '010',
        //   subs: [
        //     {
        //       index: "posterAtlas",
        //       title: "海报图集",
        //       memberRoot: true,
        //     },
        //     {
        //       index: "clockData",
        //       title: "打卡数据",
        //       memberRoot: true,
        //     },
        //   ],
        // },
        {
          icon: "iconfont icon-xiaochengxushezhi",
          index: "miniProgram",
          title: "小程序设置",
          member: true,
          subs: [
            {
              index: "appletConfig",
              title: "基础设置",
              memberRoot: true,
            },
            // {
            //   index: "basicSetting",
            //   title: "小程序配置",
            //   root:true,
            //   memberRoot:true,
            // },
            {
              index: "mallSetting",
              title: "商城设置",
              memberRoot: true,
            },
          ],
        },
      ],
      companyType:localStorage.getItem("companyType"),
      roleId:localStorage.getItem("roleId"),
      companyId:localStorage.getItem("companyId"),
      if_flag:null,
      organizationList:[]
    };
  },

  created() {
    if  (localStorage.getItem("associationId") == 51)  {
      this.items = [
        {
          icon: "iconfont icon-gongzuotai",
          index: "dashboard",
          title: "工作台",
          member: true
        },
        {
          icon: "iconfont icon-huokewenzhangguanli",
          index: "articleManagement",
          title: "动态管理",
          member: true,
          id: '07',
          subs: [
            {
              index: "toPromote",
              title: "推广文章管理",
              id: '070',
              memberRoot: true,
              subs: [
                {
                  index: "customerArticles",
                  title: "推广文章",
                  memberRoot: true,
                },
                {
                  index: "employeeData",
                  title: "员工数据",
                  memberRoot: true,
                },
                {
                  index: "visitorData",
                  title: "访客数据",
                  memberRoot: true,
                }
              ],
            },
            {
              index: "enterpriseDT",
              title: "企业动态",
              id: '071',
              memberRoot: true,
            },
          ],
        },
        {
          icon: "iconfont icon-duanshipinguanli",
          index: "videoManage",
          title: "短视频管理",
          member: true,
          id: '08',
        },
        {
          icon: "iconfont icon-shangpinguanli",
          index: "merchandiseManagement",
          title: "商品管理",
          member: true,
          id: '04',
          subs: [
            {
              index: "management",
              title: "商品管理",
              memberRoot: true,
              id: '040'
            },
            {
              index: "appointmentManagement",
              title: "预约管理",
              memberRoot: true,
              id: '041'
            },
            // {
            //   index: "demandProtuctsCheck",
            //   title: "商品审核",
            //   // root: true,
            //   // administrator: false,
            //   memberRoot: true,
            //   id: '091'
            // },
            // {
            //   index: "freightManagement",
            //   title: "运费管理",
            // },
          ],
        },
        {
          icon: "iconfont icon-dingdanguanli",
          index: "orderManagement",
          title: "订单管理",
          member: true,
          id: '03',
        },
        {
          icon: "iconfont icon-caiwuguanli",
          index: "financialManagement",
          title: "财务管理",
          member: true,
          id: '06',
          subs: [
            {
              index: "commissionManagement",
              title: "佣金管理",
              memberRoot: true,
            },
            // {
            //   index: "moneyManagement",
            //   title: "资金管理",
            // },
          ],
        },
        {
          icon: "iconfont icon-jiesuandan",
          index: "statementsQY",
          title: "结算单",
          member: true,
          id: '10'
        },
        {
          icon: "iconfont icon-yingxiaoguanli",
          index: "marketingActivities",
          title: "营销活动",
          member: true,
          id: '05',
          subs: [
            {
              index: "distribution",
              title: "分销",
              id: '050',
              memberRoot: true,
              subs: [
                {
                  index: "distributionStatistics",
                  title: "分销统计",
                  memberRoot: true,
                },
                {
                  index: "personnelApplication",
                  title: "人员申请",
                  memberRoot: true,
                },
                {
                  index: "distributionLevel",
                  title: "分销等级",
                  memberRoot: true,
                },
                {
                  index: "distributionOrder",
                  title: "分销订单",
                  memberRoot: true,
                },
                {
                  index: "accountTransfer",
                  title: "到账流水",
                  memberRoot: true,
                },
              ],
            },
            {
              index: "joinGroup",
              title: "拼团",
              memberRoot: true,
              id: '051',
              subs: [
                {
                  index: "productList",
                  title: "拼团商品列表",
                  memberRoot: true,
                },
                {
                  index: "listParticipants",
                  title: "参团列表",
                  memberRoot: true,
                },
              ],
            },
            {
              index: "spike",
              title: "秒杀",
              memberRoot: true,
              id: '052',
              subs: [
                {
                  index: "spikeList",
                  title: "秒杀商品列表",
                  memberRoot: true,
                },
              ],
            },
            {
              index: "couponManagement",
              title: "优惠券管理",
              id: '053',
              memberRoot: true,
              subs: [
                {
                  index: "couponList",
                  title: "优惠券列表",
                  memberRoot: true,
                },
              ],
            },
          ],
        },
        {
          icon: "iconfont icon-qiyeguanli-01",
          index: "3",
          title: "企业管理",
          member: true,
          id: '02',
          subs: [
            {
              index: "corporateInformation",
              title: "企业信息",
              memberRoot: true,
              id: '020'
            },
            {
              index: "enterpriseMember",
              title: "企业成员",
              id: '021',
              memberRoot: true,
            },
            {
              index: "advancedForm",
              title: "高级表单",
              memberRoot: true,
              id: '022'
            },
            // {
            //   index: "websiteSettings",
            //   title: "官网设置",
            // },
            {
              index: "advertisingManagement",
              title: "广告图管理",
              memberRoot: true,
              id: '023'
            },
            {
              index: "businessCard",
              title: "编辑名片",
              memberRoot: true,
              id: '024'
            },
            {
              index: "commonFun",
              title: "常用功能",
              memberRoot: true,
              id: '025'
            },
          ],
        },
        {
          icon: "iconfont icon-yidongguanwangguanli",
          index: "mobileWeb",
          title: "移动官网管理",
          member: true,
          id: '11',
        },
        // {
        //   icon: "iconfont icon-zengzhifuwu",
        //   index: "addedServices",
        //   title: "增值服务",
        //   member: true,
        //   id: '09',
        //   subs: [
        //     {
        //       index: "myNeeds",
        //       title: "我的需求",
        //       memberRoot: true,
        //     },
        //     {
        //       index: "demandCheck",
        //       title: "需求审核",
        //       root: true,
        //       administrator: false,
        //       memberRoot: true,
        //       id: '090'
        //     },
            // {
            //   index: "demandContactsCheck",
            //   title: "人脉集市审核",
            //   root: true,
            //   administrator: false,
            //   memberRoot: true,
            //   id: '092'
            // },
            // {
            //   index: "demandProtuctsCheck",
            //   title: "产品中心审核",
            //   root: true,
            //   administrator: false,
            //   memberRoot: true,
            //   id: '091'
            // },
            // {
            //   index: "demandPublishSetting",
            //   title: "发布设置",
            //   memberRoot: true,
            //   administrator: true,
            //   root: true,
            //   id: '093'
            // },
            // {
            //   index: "demandReport",
            //   title: "举报处理",
            //   memberRoot: true,
            //   administrator: false,
            //   root: true,
            //   id: '090'
            // },
            // {
            //   index: "demandCustomize",
            //   title: "自定义设置",
            //   memberRoot: true,
            //   administrator: false,
            //   root: true,
            //   id: '094'
            // },

        //   ],
        // },
        // {
        //   icon: '#icon-share',
        //   index: "sharingPromotion",
        //   title: "分享推广管理",
        //   member: true,
        //   id: '010',
        //   subs: [
        //     {
        //       index: "posterAtlas",
        //       title: "海报图集",
        //       memberRoot: true,
        //     },
        //     {
        //       index: "clockData",
        //       title: "打卡数据",
        //       memberRoot: true,
        //     },
        //   ],
        // },
        {
          icon: "iconfont icon-xiaochengxushezhi",
          index: "miniProgram",
          title: "小程序设置",
          member: true,
          subs: [
            {
              index: "appletConfig",
              title: "基础设置",
              memberRoot: true,
            },
            // {
            //   index: "basicSetting",
            //   title: "小程序配置",
            //   root:true,
            //   memberRoot:true,
            // },
            {
              index: "mallSetting",
              title: "商城设置",
              memberRoot: true,
            },
          ],
        },
        // {
        //   icon: "iconfont icon-kehuguanli",
        //   index: "customer",
        //   title: "客户管理",
        //   member: true,
        //   type: 'customer',
        //   subs: [
        //     {
        //       index: "customerInput",
        //       title: "客户录入",
        //       memberRoot: true,
        //     },
        //     {
        //       index: "customerManagement",
        //       title: "客户管理",
        //       memberRoot: true,
        //     },
        //     {
        //       index: "totalCustomer",
        //       title: "总客户池",
        //       memberRoot: true,
        //       id: '01',
        //     },
        //     // {
        //     //   index: "visitCustomers",
        //     //   title: "访问客户",
        //     // },
        //   ],
        // },
      ]
    }else{
      this.items = [
        {
          icon: "iconfont icon-gongzuotai",
          index: "dashboard",
          title: "工作台",
          member: true
        },
        {
          icon: "iconfont icon-kehuguanli",
          index: "customer",
          title: "客户管理",
          member: true,
          type: 'customer',
          subs: [
            {
              index: "customerInput",
              title: "客户录入",
              memberRoot: true,
            },
            {
              index: "customerManagement",
              title: "客户管理",
              memberRoot: true,
            },
            {
              index: "totalCustomer",
              title: "总客户池",
              memberRoot: true,
              id: '01',
            },
            // {
            //   index: "visitCustomers",
            //   title: "访问客户",
            // },
          ],
        },
        {
          icon: "iconfont icon-qiyeguanli-01",
          index: "3",
          title: "企业管理",
          member: true,
          id: '02',
          subs: [
            {
              index: "corporateInformation",
              title: "企业信息",
              memberRoot: true,
              id: '020'
            },
            {
              index: "enterpriseMember",
              title: "企业成员",
              id: '021',
              memberRoot: true,
            },
            {
              index: "advancedForm",
              title: "高级表单",
              memberRoot: true,
              id: '022'
            },
            // {
            //   index: "websiteSettings",
            //   title: "官网设置",
            // },
            {
              index: "advertisingManagement",
              title: "广告图管理",
              memberRoot: true,
              id: '023'
            },
            {
              index: "businessCard",
              title: "编辑名片",
              memberRoot: true,
              id: '024'
            },
            {
              index: "commonFun",
              title: "常用功能",
              memberRoot: true,
              id: '025'
            },
          ],
        },
        {
          icon: "iconfont icon-yidongguanwangguanli",
          index: "mobileWeb",
          title: "移动官网管理",
          member: true,
          id: '11',
        },
        {
          icon: "iconfont icon-dingdanguanli",
          index: "orderManagement",
          title: "订单管理",
          member: true,
          id: '03',
        },
        {
          icon: "iconfont icon-jiesuandan",
          index: "statementsQY",
          title: "结算单",
          member: true,
          id: '10'
        },
        {
          icon: "iconfont icon-shangpinguanli",
          index: "merchandiseManagement",
          title: "商品管理",
          member: true,
          id: '04',
          subs: [
            {
              index: "management",
              title: "商品管理",
              memberRoot: true,
              id: '040'
            },
            {
              index: "appointmentManagement",
              title: "预约管理",
              memberRoot: true,
              id: '041'
            },
            // {
            //   index: "demandProtuctsCheck",
            //   title: "商品审核",
            //   // root: true,
            //   // administrator: false,
            //   memberRoot: true,
            //   id: '091'
            // },
            // {
            //   index: "freightManagement",
            //   title: "运费管理",
            // },
          ],
        },
        {
          icon: "iconfont icon-yingxiaoguanli",
          index: "marketingActivities",
          title: "营销活动",
          member: true,
          id: '05',
          subs: [
            {
              index: "distribution",
              title: "分销",
              id: '050',
              memberRoot: true,
              subs: [
                {
                  index: "distributionStatistics",
                  title: "分销统计",
                  memberRoot: true,
                },
                {
                  index: "personnelApplication",
                  title: "人员申请",
                  memberRoot: true,
                },
                {
                  index: "distributionLevel",
                  title: "分销等级",
                  memberRoot: true,
                },
                {
                  index: "distributionOrder",
                  title: "分销订单",
                  memberRoot: true,
                },
                {
                  index: "accountTransfer",
                  title: "到账流水",
                  memberRoot: true,
                },
              ],
            },
            {
              index: "joinGroup",
              title: "拼团",
              memberRoot: true,
              id: '051',
              subs: [
                {
                  index: "productList",
                  title: "拼团商品列表",
                  memberRoot: true,
                },
                {
                  index: "listParticipants",
                  title: "参团列表",
                  memberRoot: true,
                },
              ],
            },
            {
              index: "spike",
              title: "秒杀",
              memberRoot: true,
              id: '052',
              subs: [
                {
                  index: "spikeList",
                  title: "秒杀商品列表",
                  memberRoot: true,
                },
              ],
            },
            {
              index: "couponManagement",
              title: "优惠券管理",
              id: '053',
              memberRoot: true,
              subs: [
                {
                  index: "couponList",
                  title: "优惠券列表",
                  memberRoot: true,
                },
              ],
            },
          ],
        },
        {
          icon: "iconfont icon-caiwuguanli",
          index: "financialManagement",
          title: "财务管理",
          member: true,
          id: '06',
          subs: [
            {
              index: "commissionManagement",
              title: "佣金管理",
              memberRoot: true,
            },
            // {
            //   index: "moneyManagement",
            //   title: "资金管理",
            // },
          ],
        },
        {
          icon: "iconfont icon-huokewenzhangguanli",
          index: "articleManagement",
          title: "动态管理",
          member: true,
          id: '07',
          subs: [
            {
              index: "toPromote",
              title: "推广文章管理",
              id: '070',
              memberRoot: true,
              subs: [
                {
                  index: "customerArticles",
                  title: "推广文章",
                  memberRoot: true,
                },
                {
                  index: "employeeData",
                  title: "员工数据",
                  memberRoot: true,
                },
                {
                  index: "visitorData",
                  title: "访客数据",
                  memberRoot: true,
                }
              ],
            },
            {
              index: "enterpriseDT",
              title: "企业动态",
              id: '071',
              memberRoot: true,
            },
          ],
        },
        {
          icon: "iconfont icon-duanshipinguanli",
          index: "videoManage",
          title: "短视频管理",
          member: true,
          id: '08',
        },
        // {
        //   icon: "iconfont icon-zengzhifuwu",
        //   index: "addedServices",
        //   title: "增值服务",
        //   member: true,
        //   id: '09',
        //   subs: [
        //     {
        //       index: "myNeeds",
        //       title: "我的需求",
        //       memberRoot: true,
        //     },
        //     {
        //       index: "demandCheck",
        //       title: "需求审核",
        //       root: true,
        //       administrator: false,
        //       memberRoot: true,
        //       id: '090'
        //     },
        //   ],
        // },
        // {
        //   icon: '#icon-share',
        //   index: "sharingPromotion",
        //   title: "分享推广管理",
        //   member: true,
        //   id: '010',
        //   subs: [
        //     {
        //       index: "posterAtlas",
        //       title: "海报图集",
        //       memberRoot: true,
        //     },
        //     {
        //       index: "clockData",
        //       title: "打卡数据",
        //       memberRoot: true,
        //     },
        //   ],
        // },
        {
          icon: "iconfont icon-xiaochengxushezhi",
          index: "miniProgram",
          title: "小程序设置",
          member: true,
          subs: [
            {
              index: "appletConfig",
              title: "基础设置",
              memberRoot: true,
            },
            // {
            //   index: "basicSetting",
            //   title: "小程序配置",
            //   root:true,
            //   memberRoot:true,
            // },
            {
              index: "mallSetting",
              title: "商城设置",
              memberRoot: true,
            },
          ],
        },
      ]
    }
    // 控制折叠面板
    bus.$on("collapse", (msg) => {
      this.collapse = msg;
      bus.$emit("collapse-content", msg);
    });
    this.info = JSON.parse(localStorage.getItem('info'));
    this.isMain = this.info.main
    let than = this
    this.items.map((item, index) => {
      if (this.jurisdiction.includes(item.index)) {
        // if (this.info.companyType == 1 ) {
        //   item.member = false
        // } else {
        //   item.member = true
        // }
        item.member = this.info.roleId ? true : false;
      }
      if (item.index == 'sharingPromotion') {
        item.member = this.isMain ? true : false;
      }
      if (item.subs) {
        item.subs.map(subItem => {
          if (than.memberJurisdiction.includes(subItem.index)) {
            subItem.memberRoot = than.info.roleId ? true : false;
          }
        })
      }
    })
  },
  mounted() {
    if (this.companyId == 2) {
      //服务商
      this.if_flag = 4
    }else {
      if (this.roleId == 0) {
        this.if_flag = 3
      }else if (this.companyType == '444') {
        this.if_flag = 1
        this.getNavList()
      }else{
        this.if_flag = 2
      }
    }
  },
  computed: {
    ...mapState(['routerArr']),
    // 主题颜色
    subjectColor() {
      return this.$store.state.subjectColor;
    },
    // 路由配置
    onRoute() {
      return this.$route.path.replace("/", "");
    },
    jurisdiction() {
      let arr = [
        'miniProgram',
        'sharingPromotion',
        'marketingActivities',
        'financialManagement',
        'articleManagement',
        'orderManagement',
      ]
      return arr
    },
    memberJurisdiction() {
      let arr = [
        'totalCustomer',
        'corporateInformation',
        'advertisingManagement'
      ]
      return arr
    },
  },
  watch: {
    routerArr(val) {
      if(this.roleId != 0){
        try {
          let than = this
          this.items.map(item => {
            if (item.id || item.type == 'customer') {//父级
              if (val.includes(item.id)) {
                item.member = true
              } else if (item.type == 'customer') {
                item.member = true
              } else {
                item.member = false
              }

              if (item.subs) {
                item.subs.map(subItem => {
                  if (subItem.id) {//子集

                    if (val.includes(subItem.id)) {
                      if (subItem.id == '022') {
                        than.items[2].member = true
                      }
                      subItem.memberRoot = true
                      if (subItem.administrator == false) {
                        subItem.root = false
                        subItem.administrator = true
                      }
                    } else {
                      subItem.memberRoot = false
                    }
                  }

                })
              }
            }
          })
        } catch (error) {
          console.log(error)
        }
      };
      if(this.roleId == 0){
        try {
          let than = this
          this.items2.map(item => {
            if (item.id || item.type == 'customer') {//父级
              if (val.includes(item.id)) {
                item.member = true
              } else if (item.type == 'customer') {
                item.member = true
              } else {
                item.member = false
              }

              if (item.subs) {
                item.subs.map(subItem => {
                  if (subItem.id) {//子集

                    if (val.includes(subItem.id)) {
                      if (subItem.id == '022') {
                        than.items[2].member = true
                      }
                      subItem.memberRoot = true
                      if (subItem.administrator == false) {
                        subItem.root = false
                        subItem.administrator = true
                      }
                    } else {
                      subItem.memberRoot = false
                    }
                  }

                })
              }
            }
          });
        } catch (error) {
          console.log(error)
        }
      }
    }
  },
  methods: {
    async getNavList() {
      let data = {
        assIsMain: localStorage.getItem("assIsMain"),
        associationId: localStorage.getItem("associationId"),
      };
      try {
        const result = await queryListLevelMenum(data);
        let res = result.data
        this.organizationList = res;
      } catch (error) {
      }
    },
    filterRouter(rootId) {
      let arr = this.$store.state.routerArr
      let info = JSON.parse(localStorage.getItem('info'));
      if (info.main || info.roleId) {
        return true
      }
      // if()
      let result = arr.find(item => {
        return item == rootId
      })
      return result
    }
  }
};
</script>

<style scoped>
.sidebar {
  display: block;
  position: absolute;
  width: 208px;
  left: 0;
  top: 68px;
  bottom: 0;
  overflow-y: scroll;
}

.sidebar::-webkit-scrollbar {
  display: none;
}

.sidebar-el-menu:not(.el-menu--collapse) {
  width: 208px;
}

.sidebar > ul {
  height: 100%;
}

.sidebar .el-menu {
  border-right: none;
}

.title {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* .el-menu-item {
  border-left: #fff solid 6px;
} */
/* 设置鼠标悬停时el-menu-item的样式 */
/* .el-menu-item:hover {
  border-left: #6190e8 solid 6px !important;
  background-color: #e2eff9 !important;
  color: #6190e8 !important;
} */

/* .el-menu-item:hover i {
  color: #6190e8;
} */

/* 设置选中el-menu-item时的样式 */
.el-menu-item,
.el-submenu {
  /* opacity: 0.8; */
}

.el-menu-item .is-active {
  opacity: 1;
}

.memberRoot {
  display: none;
}
.el-submenu__title i{
  color: #000000;
  margin-right: 5px;
}
</style>
